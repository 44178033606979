(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/app/svenskaspel-backbone-ajax/assets/javascripts/ajax.js') >= 0) return;  svs.modules.push('/components/app/svenskaspel-backbone-ajax/assets/javascripts/ajax.js');

var svs = svs || {};

(function(svs, Backbone) {
  'use strict';

  var logger = new svs.core.log.Logger('libs:svs.backbone.ajax');
  var jupiterUrl = svs.core.jupiter.url();

  Backbone.ajax = function(request) {
    function onError(xhr, status) {
      var params = {};
      var jsonResp = xhr && xhr.responseJSON ? xhr.responseJSON : null;

      if (jsonResp && jsonResp.error) {
        if (jsonResp.error.code) {
          params.code = jsonResp.error.code;
        }
        if (jsonResp.error.description) {
          params.description = jsonResp.error.description;
        }
        if (jsonResp.error.message) {
          params.message = jsonResp.error.message;
        }
      } else if (jsonResp && jsonResp.status) {
        if (jsonResp.status.code) {
          params.code = jsonResp.status.code;
        }
        if (jsonResp.status.description) {
          params.description = jsonResp.status.description;
        }
        if (jsonResp.message) {
          params.message = jsonResp.message;
        }
      }

      request.error(xhr, status, params);
    }

    function onGenericError(xhr, status, err) {
      logger.info('Generic request failed ' +
        (xhr.status || 'NONE') +
        ' - ' +
        (xhr.statusText || err));
      request._error(xhr, status, err);
    }

    if (request.url.indexOf(jupiterUrl) === 0) {
      logger.debug('Jupiter request');
      request.useExtErrFunc = false;
      request.method = request.type;
      request.path = request.url.substr(jupiterUrl.length);
      return svs.core.jupiter.call(request, request.success, onError);
    }
    logger.debug('Generic request');
    if (typeof request.error === 'function') {
      request._error = request.error;
      request.error = onGenericError;
    }
    return Backbone.$.ajax.apply(Backbone.$, [request]);
  };
})(svs, window.Backbone);


 })(window);